//
// Floats
//

.clearfix {
  @include clearfix();
}
.center-block {
  @include center-block();
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .pull-#{$breakpoint}-left {
      @include pull-left();
    }
    .pull-#{$breakpoint}-right {
      @include pull-right();
    }
    .pull-#{$breakpoint}-none {
      float: none !important;
    }
  }
}
//
// Screenreaders
//

.sr-only {
  @include sr-only();
}
.sr-only-focusable {
  @include sr-only-focusable();
}
.invisible {
  visibility: hidden !important;
}
.text-hide {
  @include text-hide();
}
.relative {
  position: relative;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.inline-block {
  display: inline-block;
}
.no-wrap {
  white-space: nowrap;
}
//
// Text
//

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: $input-color-placeholder;
}
// Alignment

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-justify { text-align: justify !important; }
.text-nowrap { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }
.block-center { margin: 0 auto; }
// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .text-#{$breakpoint}-left { text-align: left !important; }
    .text-#{$breakpoint}-right { text-align: right !important; }
    .text-#{$breakpoint}-center { text-align: center !important; }
  }
}
// Transformation
.text-lowercase { text-transform: lowercase !important; }
.text-uppercase { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.text-strike { text-decoration: line-through !important; }
.text-underline { text-decoration: underline; }
// Weight and italics
.font-weight-light { font-weight: 200; }
.font-weight-normal { font-weight: normal; }
.font-weight-bold { font-weight: bold; }
.font-italic { font-style: italic; }
// Contextual colors
.text-muted { color: $text-muted; }
@include text-emphasis-variant('.text-primary', $brand-primary);
@include text-emphasis-variant('.text-success', $brand-success);
@include text-emphasis-variant('.text-info', $brand-info);
@include text-emphasis-variant('.text-warning', $brand-warning);
@include text-emphasis-variant('.text-danger', $brand-danger);
