.cart-head {
  background: url("/img/header-img2.jpg") no-repeat;
  background-size: cover;
}

.top {
  form {
    .continue-order {
      padding: 1rem 3rem;
    }
  }
}

.content-default {
  margin-bottom: 2rem
}

.cart-title {
  background-color: $color-cart-items-bg;
  padding: 2rem 0;
  margin-bottom: 2rem;

  .container-fluid {
    width: 60%;
    min-width: 836px;
    margin-left: auto;
    margin-right: auto;
  }
  @media(max-width: 836px) {
    .container-fluid {
      width: 100%;
      min-width: 0;
    }
  }

  ul {
    overflow: hidden;
    margin: 0;
    max-width: 100%;
    padding: 0;
    li {
      list-style: none;
      padding: 0;
      a {
        display: block;
        padding: 10px 20px;
        font-family: $font-general;
        font-size: 16px;
        background-color: $color-white-texts;
        color: $color-cart;
        text-transform: uppercase;
        line-height: 28px;
        span {
          font-size: 28px;
          margin-right: 20px;
          vertical-align: bottom;
        }
      }

      &.active a {
        background-color: $color-cart;
        color: $color-white-texts;
      }
    }
    li + li {
      padding-left: 0.5rem;
    }

    @media (max-width: 47.9em) {
      li + li {
        padding-left: 0;
      }
    }
  }
}

.cart-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;

  .btn-success,
  .btn-success:hover,
  .btn-success:focus,
  .btn-success:active,
  .btn-success:visited {
    background: none;
    background-color: $color-cart;
    border: none;
    font-size: 130%;
    color: $color-white-texts;
    text-transform: uppercase;
    padding: 0.5rem 5rem;
  }

  .cart-bar,
  .cart-bar:hover,
  .cart-bar:active,
  .cart-bar:visited,
  .cart-bar:focus {
    font-size: 130%;
    line-height: 2rem;
    background: $color-cart-light url("/img/btn-sipka-doleva.svg") no-repeat left;
    background-position-x: 0.5rem;
    background-size: auto 60%;
    padding: 0.7rem 1rem 0.7rem 3.5rem;
  }

  .continue-order {
    font-size: 130%;
    line-height: 2rem;
    padding: 0.7rem 2rem;
  }
}

.price-info {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.vat-note {
  padding-left: 1rem;
  font-family: $font-general;
  font-weight: 300;
  font-size: 120%;
  width: auto;
  a {
    color: $color-arrows;
  }
}

.total-price {

  padding-right: 1rem;
  text-align: right;
  .price-label {
    color: $color-general;
  }
  .price-value {
    color: $color-general;
    display: inline-block;
    width: 10rem;
  }
}

@media(max-width: 47.9rem) {
  .price-info {
    display: block;

    .values {
      padding-left: 1rem;
      width: 100%;
      overflow: hidden;
      .price-label {
        width: auto;
        clear: both;
        float: left;
        margin-bottom: 1rem;
      }
      .price-value {
        margin-bottom: 1rem;
        width: auto;
        clear: right;
        float: right;
      }
    }
    .vat-note {
      margin-bottom: 2rem;
      width: 100%;
      padding: 0;
      text-align: center;
    }

  }
  .cart-bottom {
    .cart-bar {
      font-size: 0;
      padding-right: 0;
      width: 2rem;
    }
    .continue-order, .btn-success,
    .continue-order:hover, .btn-success:hover,
    .continue-order:active, .btn-success:active,
    .continue-order:visited, .btn-success:visited,
    .continue-order:focus, .btn-success:focus {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  .sum-bottom {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex: wrap;
    .continue-order {
      padding-left: 0;
      padding-right: 0;
      flex-grow: 2;
      -webkit-flex-grow: 2;
    }
  }
}

.c-radio, .c-checkbox {
  .c-indicator {
    border-radius: 0;
    background: $color-border none;
    border-color: $color-border;
  }

  input ~ .c-indicator {
    background: $color-white-texts none;
    border-radius: 0;
    border-color: $color-border;
  }

  input:checked ~ .c-indicator {
    background: $color-border none;
    border-color: $color-border;
  }
}

// -------------------
$height: 5rem;
.cart-items {

  padding: 0;
  margin-bottom: 1rem;
  list-style-type: none;
  color: $color-general;
  .cart-item{
    padding: 0.5rem;
    .text-info{
      color: $color-general !important;
    }
    h4{
      color: $color-general;
    }
  }
  .row {
    margin: 0;
    padding: 0;
    width: 100%;
    height: $height;
  }
  .form-control {
    //border: 0.1rem solid $color-cart-border;
  }
  .row > div {
    padding-left: 0;
    padding-right: 0;
    height: $height;
    line-height: $height;
    white-space: nowrap;
  }
}

@media(max-width: 47.9em) {
  .cart-items {
    .row {
      height: auto;
    }
    .row > div {
      height: $height;
    }
  }

}

.delete-btn {
  padding: 0 1rem 0 0;
  img {
    height: 100%;
  }
  height: 2rem;
  background: none;
}

.cart-item {
  background-color: $color-cart-items-bg;
  padding: 0;
  margin-bottom: 0.8rem;

}
.cart-item-img {
  img {
    display: inline-block !important;
  }
}
.cart-item-name {
  padding: 0;
  margin: 0;
  height: 5rem;
  padding-left: 1rem !important;

  h4 {
    height: $height;
    line-height: $height;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
  }

  .img-fluid {
    height: 5rem;
    display: inline-block;
    width: auto;
  }
  span {
    align-self: flex-start;
    justify-self: flex-start;
    margin: 0 0 0 1rem;
    font-family: $font-general;
  }
}

.cart-item-remove, .cart-item-price {
  margin: 0;
}

.no-items {
  font-size: 300%;
  color: $color-cart-light;
  font-family: Oswald, sans-serif;
  margin-top: 10rem;
  text-align: center;
  margin-bottom: 10rem;
}

.pull-right, .free {
  font-family: Oswald, sans-serif;
  font-weight: 400;
}

.pull-right {
  font-family: $font-general;
}

.free {
  color: $color-onstock;
}

// -------------------
.shipping-and-payment {
  h3 {
    font-family: $font-general;
    font-weight: 400;
  }
  label, span {
    font-family: $font-general;
    font-weight: 300;
  }
  span {
    font-weight: 400;
  }

  .row {
    margin-top: 1rem;
  }
  .col-sm-6 {
    padding: 0;
    background: none;
    .inner {
      margin: 0 0.5rem;
      background-color: $color-cart-items-bg;
      padding: 1rem;
    }
  }
}

.delivery-inner {
  margin-top: 2rem;
  padding: 2rem;
  background-color: $color-cart-items-bg;
  h3 {
    font-weight: 700;
  }
  .form-error {
    display: none;
  }
}

.summary-head {
  margin-top: 2rem;
  margin-bottom: 0.8rem;
  padding: 1rem;
  background-color: $color-cart-items-bg;
  .top {
    display: -webkit-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  } 
  h2, h3, p, button {
    font-family: $font-general;
  }
  h2 {
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 2rem;
  }
  .btn-light {
    display: block;
  }
}

.sum-delivery {
  margin: 0;
  padding: 0;
  width: 100%;
  .col-sm-6 {
    padding: 0;
    h4 {
      font-family: Oswald, sans-serif;
      margin-bottom: 1rem;
    }
    div {
      background-color: $color-cart-items-bg;
      padding: 2rem 1rem;
    }
  }
  .col-sm-6 + .col-sm-6 {
    padding-left: 0.8rem;
  }
  @media(max-width: 47.9em) {
    .col-sm-6 + .col-sm-6 {
      padding-left: 0;
      padding-top: 0.8rem;
    }

  }

}

.thank-you {
  margin-top: 3rem;
}

.c-input input:active ~ .c-indicator {
  background-color: $color-cart-light;
}

.c-input .c-indicator {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.c-input input:focus:checked ~ .c-indicator {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}