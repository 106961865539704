//
// Basic Bootstrap table
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;

  th,
  td {
    padding: $table-cell-padding;
    line-height: $line-height;
    vertical-align: middle;
    //vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  tr:last-child {
    th, td {
      border-bottom: $table-border-width solid $table-border-color;
    }
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}
//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-sm-cell-padding;

    //.form-control {
    //  @extend .form-control-sm;
    //}
    .form-control-static {
      padding-top: 0.265rem;
      padding-bottom: 0.265rem;
      min-height: 1.9885rem;
    }
    .checkbox {
      padding: 0;
    }
  }
}
// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}
// Borderless version
//
// Add borders all around the table and between all the columns.

.table-borderless {
  border: 0 solid $table-border-color !important;

  tr, tr:last-child {
    th,
    td {
      border: 0 solid $table-border-color;
    }
  }

  thead {
    tr, tr:last-child {
      th,
      td {
        //border-bottom-width:0;
        border-bottom-width: (2 * $table-border-width);
      }
    }
  }
  tfoot {
    tr, tr:last-child {
      th,
      td {
        //border-bottom-width:0;
        //border-top-width: (2 * $table-border-width);
      }
    }
  }
}
// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }
}
// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-bg-hover;
    }
  }
}
// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include table-row-variant(active, $table-bg-active);
@include table-row-variant(success, $state-success-bg);
@include table-row-variant(info, $state-info-bg);
@include table-row-variant(warning, $state-warning-bg);
@include table-row-variant(danger, $state-danger-bg);

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  display: block;
  width: 100%;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
  overflow-x: auto;

  // TODO: find out if we need this still.
  //
  // border: $table-border-width solid $table-border-color;
  // -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
}
.thead-inverse {
  th {
    color: #fff;
    background-color: $gray-dark;
  }
}
.thead-default {
  th {
    color: $gray;
    background-color: $gray-lighter;
  }
}
.table-inverse {
  color: $gray-lighter;
  background-color: $gray-dark;

  &.table-bordered {
    border: 0;
  }

  th,
  td,
  thead th {
    border-color: $gray;
  }
}
.table-sortable {
  thead {
    th {
      position: relative;
    }

    th.sort {
      cursor: pointer;
      padding-right: 1rem;

      &::after {
        content: "\f0dc";
        position: absolute;
        font-family: FontAwesome;
        right: 0.3rem;
        top: auto;
        font-size: 0.8rem;
        line-height: 1.875;
      }
    }
    th.sort-up {
      &::after {
        content: "\f0de";
      }

    }
    th.sort-down {
      &::after {
        content: "\f0dd";
      }
    }
    th.active, th.sort:hover {
      background: $gray-lighter;
      &::after {
        color: $brand-danger;
      }
    }
  }
}
.table-reflow {
  thead {
    float: left;
  }

  tbody {
    display: block;
    white-space: nowrap;
  }

  th,
  td {
    border-top: $table-border-width solid $table-border-color;
    border-left: $table-border-width solid $table-border-color;

    &:last-child {
      border-right: $table-border-width solid $table-border-color;
    }
  }

  thead,
  tbody,
  tfoot {
    &:last-child {
      tr:last-child {
        th,
        td {
          border-bottom: $table-border-width solid $table-border-color;
        }
      }
    }
  }

  tr {
    float: left;

    th,
    td {
      display: block !important;
      border: $table-border-width solid $table-border-color;
    }
  }
}
.table .form-group {
  margin: 0;
}
