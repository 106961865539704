#register {
  .form-group {
    &.company {
      display: none;
    }
  }
}

#login {
  margin-top: 3rem;
}

.box {
  border: none;
  padding: 1rem;
  background-color: $color-cart-items-bg;
  a {
    color: $color-arrows;
  }
}

.settings {
  padding-top: 1rem;
}

#page{

}