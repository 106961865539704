.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $spacer-y;
  list-style: none;
  //background-color: $breadcrumb-bg;
  background: none;
  @include border-radius($border-radius);
  @include clearfix;

  > li {
    float: left;

    + li::before {
      padding-right: .5rem;
      padding-left: .5rem;
      color: $breadcrumb-divider-color;
      font-family: $breadcrumb-font;
      content: "#{$breadcrumb-divider}";
    }

    .divider {
      position: absolute;
      top: -999px;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
