.underline-dark {
  display: inline;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    opacity: .8;    
    right: 0;
    width: 0;
    bottom: -2px;
    background: #585453;
    height: 2.5px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover:after, :focus:after, :active:after {     
    left: 0;
    right: auto;
    width: 100%;        
  }
}

#footer {
  //position: relative;
  //width: 100%;
  //padding: 0;
  //margin-top: 6rem;

  .bottom span:last-child {
    &::before {
      content: none !important;
    }
  }

  .container-fluid.credit {
    &::after {
      content: none;
    }
  }

  .credit {
    display: -webkit-box;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    color: #FFF;
    @media (max-width: 576px) {
      flex-direction: column;
    }
    .inspi {
      @media (max-width: 576px) {
        margin-top: 1rem;
      }
      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        line-height: 1.3;        
        color: #FFF;
        .inspishop {                        
          &:hover {
            color: #003869 !important;
          }
        }
        .font-inspirum {
          color: #FFF;
          transition: all .3s linear;
          &:hover {
            color: #009e84;
            opacity: .85;
          }
        }
      }
    }
  }

  #map-canvas{
    width: 100%;
    height: 50vh;
  }

  .links-outer {
    position: relative;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 4rem;
    width: 100%;
    background-color: $color-footer-bg-light;
    text-align: center;
    .fb-footer {
      margin: 0 auto;
      width: auto;
      img {
        height: 2rem;
      }
    }
    .links-inner {
      margin-top: 2rem;
      text-transform: uppercase;
      font-family: Oswald, sans-serif;
    }
    .btn {
      color: $color-footer-links-dark;

    }
  }

  .bottom {
    padding: 2rem 0;
    width: 100%;
    text-align: center;
    background-color: $color-footer-bg-dark;
    font-family: "Noticia Text Bold", sans-serif;
    p {
      color: $color-footer-text2;
      a {
        color: $color-footer-link;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
    span:last-child:before {
      content: " | ";
    }

  }
}

@media (max-width: 47.9em) {
  #footer .links-inner {
    * {
      display: block;
    }
  }

}
