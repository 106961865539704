// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #aba2a2;
$gray-lighter: #d2cac5;
$gray-lightest: #f7f7f9;

$biege: #ede8de;
$biege-dark: #dfdad0;
$white: #fff;
$yellow: #ffbb18;
$orange: #f6af32;
$green: #b6c54d;
$brown: #604734;
$red: #ff3419;

$light-blue: #4ed7ff;
$purple: #8575a5;

$brand-primary: $orange;
$brand-success: $green;
$brand-info: $light-blue;
$brand-warning: $orange;
$brand-danger: $red;

$img-path: '/img/';
$svg-icons-path: '/img/svg/';

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$border-width: 1px;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff;
$body-color: $gray-dark;
$border-color: #ccc;
$border-light-color: $gray-lighter;

// Links
//
// Style anchor elements.

$link-color: $orange;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 760px,
        md: 1030px,
        lg: 1030px,
        xl: 1290px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 700px,
        md: 1030px,
        lg: 1030px,
        xl: 1260px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 1.875rem; // 30px

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-heading: 'Oswald', sans-serif;
$font-family-base: $font-family-sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px;

$font-size-base: 1rem;
$font-size-xl: 1.75rem;
$font-size-lg: 1.1rem;
$font-size-sm: .875rem;
$font-size-xs: .875rem;

$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 400;
$display2-weight: 400;
$display3-weight: 400;
$display4-weight: 400;

$line-height: 1.5;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: 400;
$headings-line-height: 1.1;
$headings-color: inherit;

$lead-font-size: 1.25rem;
$lead-font-weight: 400;

$text-muted: $gray-light;

$abbr-border-color: $gray-light;

$blockquote-small-color: $gray-light;
$blockquote-font-size: ($font-size-base * 1.25);
$blockquote-border-color: $gray-lighter;

$hr-border-color: $border-color;
$hr-border-width: $border-width;

$dt-font-weight: bold;

$nested-kbd-font-weight: bold;

$list-inline-padding: 5px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-xl: (4 / 3);
$line-height-lg: (4 / 3);
$line-height-sm: 1.5;
$line-height-xs: 1.8;

$border-radius: 0rem;
$border-radius-lg: 0rem;
$border-radius-sm: 0rem;

$component-active-color: #fff;
$component-active-bg: $brand-primary;

$caret-width: .3em;
$caret-width-lg: $caret-width;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: .75rem;
$table-sm-cell-padding: .3rem;

$table-bg: transparent;
$table-bg-accent: #f9f9f9;
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;

$table-border-width: $border-width;
$table-border-color: $border-color;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x: 1rem;
$btn-padding-y: .375rem;
$btn-font-weight: normal;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $btn-primary-bg;

$btn-secondary-color: $gray-dark;
$btn-secondary-bg: #fff;
$btn-secondary-border: #ccc;

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: $btn-info-bg;

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: $btn-success-bg;

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: $btn-warning-bg;

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $btn-danger-bg;

$btn-link-disabled-color: $gray-light;

$btn-padding-x-xl: 1.25rem;
$btn-padding-y-xl: .75rem;

$btn-padding-x-lg: 1.25rem;
$btn-padding-y-lg: .75rem;

$btn-padding-x-sm: .50rem;
$btn-padding-y-sm: .25rem;

$btn-padding-x-xs: .3rem;
$btn-padding-y-xs: .1rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

// Forms

$input-padding-x: .75rem;
$input-padding-y: .375rem;

$input-bg: #fff;
$input-bg-disabled: $gray-lighter;

$input-color: $gray;
$input-border-color: $border-color;
$input-btn-border-width: $border-width; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;
$checkbox-border-radius: 0;

$input-border-focus: #66afe9;
$input-box-shadow-focus: rgba(102, 175, 233, .6);

$input-color-placeholder: #999;

$input-padding-x-sm: .75rem;
$input-padding-y-sm: .275rem;

$input-padding-x-lg: 1.25rem;
$input-padding-y-lg: .75rem;

$input-height: (($font-size-base * $line-height) + ($input-padding-y * 2));
$input-height-lg: (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2));
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$form-group-margin-bottom: $spacer-y;

$input-group-addon-bg: $gray-lighter;
$input-group-addon-border-color: $input-border-color;

$cursor-disabled: not-allowed;

// Form validation icons
$form-icon-success: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
$form-icon-warning: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
$form-icon-danger: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
$form-icon-height: 1.625rem;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg: #fff;
$dropdown-border-color: rgba(0, 0, 0, .15);
$dropdown-border-width: $border-width;
$dropdown-divider-bg: #e5e5e5;

$dropdown-link-color: $gray-dark;
$dropdown-link-hover-color: darken($gray-dark, 5%);
$dropdown-link-hover-bg: #f5f5f5;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: $gray-light;

$dropdown-header-color: $gray-light;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-navbar-sticky: 1030;
$zindex-modal-bg: 1040;
$zindex-modal: 1050;

// Navbar

$navbar-border-radius: $border-radius;
$navbar-padding-horizontal: $spacer;
$navbar-padding-vertical: ($spacer / 2);

$navbar-dark-color: rgba(255, 255, 255, .5);
$navbar-dark-hover-color: rgba(255, 255, 255, .75);
$navbar-dark-active-color: rgba(255, 255, 255, 1);
$navbar-dark-disabled-color: rgba(255, 255, 255, .25);

$navbar-light-color: rgba(0, 0, 0, .3);
$navbar-light-hover-color: rgba(0, 0, 0, .6);
$navbar-light-active-color: rgba(0, 0, 0, .8);
$navbar-light-disabled-color: rgba(0, 0, 0, .15);

// Navs

$nav-link-padding: .5em 1em;
$nav-link-hover-bg: $gray-lighter;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

$nav-tabs-border-color: $border-color;

$nav-tabs-link-border-width: $border-width;
$nav-tabs-link-hover-border-color: $border-light-color;

$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: $border-color;

$nav-tabs-justified-link-border-color: $border-color;
$nav-tabs-justified-active-link-border-color: $body-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

// Pagination

$pagination-padding-x: .75rem;
$pagination-padding-y: .3rem;
$pagination-padding-x-sm: .75rem;
$pagination-padding-y-sm: .275rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-padding-y-lg: .75rem;

$pagination-color: $link-color;
$pagination-bg: #fff;
$pagination-border-width: $border-width;
$pagination-border-color: #ddd;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: #ddd;

$pagination-active-color: #fff;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #ddd;

// Pager

$pager-bg: $pagination-bg;
$pager-border-width: $border-width;
$pager-border-color: $pagination-border-color;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

// Jumbotron

$jumbotron-padding: 2rem;
$jumbotron-bg: $gray-lighter;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken($state-success-bg, 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken($state-info-bg, 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken($state-warning-bg, 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken($state-danger-bg, 5%);

// Cards
$card-spacer-x: 1.25rem;
$card-spacer-y: .75rem;
$card-border-width: 1px;
$card-border-radius: $border-radius;
$card-border-color: #e5e5e5;
$card-border-radius-inner: $card-border-radius;
$card-cap-bg: #f5f5f5;
$card-bg: #fff;

$card-link-hover-color: #fff;

// Tooltips

$tooltip-max-width: 200px;
$tooltip-color: #fff;
$tooltip-bg: #000;
$tooltip-opacity: .9;

$tooltip-arrow-width: 5px;
$tooltip-arrow-color: $tooltip-bg;

// Popovers

$popover-bg: #fff;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba(0, 0, 0, .2);

$popover-title-bg: darken($popover-bg, 3%);

$popover-arrow-width: 10px;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-width: ($popover-arrow-width + 1);
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Labels

$label-default-bg: $gray-light;
$label-primary-bg: $brand-primary;
$label-success-bg: $brand-success;
$label-info-bg: $brand-info;
$label-warning-bg: $brand-warning;
$label-danger-bg: $brand-danger;

$label-color: #fff;
$label-link-hover-color: #fff;
$label-font-weight: bold;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 15px;

$modal-title-padding: 15px;
$modal-title-line-height: $line-height;

$modal-content-bg: #fff;
$modal-content-border-color: rgba(0, 0, 0, .2);

$modal-backdrop-bg: #000;
$modal-backdrop-opacity: .5;
$modal-header-border-color: #e5e5e5;
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius;
$alert-link-font-weight: bold;
$alert-border-width: $border-width;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

// Progress bars

$progress-bg: #f5f5f5;
$progress-bar-color: #fff;
$progress-border-radius: $border-radius;

$progress-bar-bg: $brand-primary;
$progress-bar-success-bg: $brand-success;
$progress-bar-warning-bg: $brand-warning;
$progress-bar-danger-bg: $brand-danger;
$progress-bar-info-bg: $brand-info;

// List group

$list-group-bg: #fff;
$list-group-border-color: #ddd;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-hover-bg: #f5f5f5;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border: $list-group-active-bg;
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

$list-group-disabled-color: $gray-light;
$list-group-disabled-bg: $gray-lighter;
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;

// Image thumbnails

$thumbnail-padding: .25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: #ddd;
$thumbnail-border-radius: $border-radius;

// Breadcrumbs

$breadcrumb-padding-vertical: .5rem;
$breadcrumb-padding-horizontal: .75rem;

$breadcrumb-bg: $gray-lighter;
$breadcrumb-divider-color: $gray-light;
$breadcrumb-active-color: $gray-light;
$breadcrumb-font: FontAwesome;
$breadcrumb-divider: "\f105";

// Carousel

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-color: #fff;

// Close

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;

// Flags

$flag-icon-css-path: '/img/flags';
$flag-icon-rect-path: '/4x3';
$flag-icon-square-path: '/1x1';

$enable-flex: false;

// *** my colors  ****
//hp
$color-general: #001f2a;
$color-hp-products-bg: #fef6ea;
$color-white-texts: #fff;
$color-products: rgba(49, 64, 69, 255);
$color-more-coffee: #59534c;

//Footer
$color-footer-link: #fff0da;
$color-footer-bg-dark: #3a342f;
$color-footer-bg-light: #f5f2f1;
$color-footer-links-dark: rgba(88, 84, 83, 255);
$color-footer-text: rgba(255, 240, 218, 255);
$color-footer-text2: rgba(245, 242, 241, 255);

//Detail zboží
$color-spec-text: #5f6c7b;
$color-onstock: #9ba843;
$color-specline: #cac9c7;
$color-arrows: #8b7661;
$color-detail-th: #5e6d7c;
// kosik
$color-cart: #26241b;
$color-cart-light: #bfc2c6;
$color-cart-items-bg: #f6f5f3;
$color-cart-border: #cdcdcd;
$color-border: #d0c9c0;
/*
font-family: 'Oswald', sans-serif;
font-family: 'Pathway Gothic One', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Noticia Text', serif;
*/
$font-general: 'Oswald', sans;
$font-secondary: OpenSans, sans-serif;

$font-family-sans: $font-general;
$font-family-sans-serif: $font-secondary;