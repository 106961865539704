.home-section,
.banners-section,
.about-section {
  position: relative;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.home-section {
  height: 100vh;
  font-family: $font-general;
  background: darkslategray url($img-path + "/homepage-2560.jpg") no-repeat;
  background-size: cover;
  color: $color-white-texts;

  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: flex-end;
  -webkit-align-items: flex-end;

  .heading {
    margin-top: 128px;
    width: 100%;

    h1, p {
      text-transform: uppercase;
      font-weight: 300;
    }
    h1 {
      margin-bottom: 2rem;
      font-size: 600%;
      @media (max-width: 576px) {
        margin-top: 4rem;
        font-size: 400%;
      }
      @media (max-height: 414px) {
        font-size: 400%;
      }
    }
    p {
      margin-bottom: 5rem;
      font-size: 320%;
      @media (max-width: 576px) {
        margin-bottom: 2rem;
      }
    }
    .btn-hero {
      display: inline-block;
      font-size: 1rem;
      .btn-nabidka {
        position: relative;
        display: block;
        margin: 0 2px;              
        text-transform: uppercase;        
        overflow: hidden;       
        border: .15rem solid #fff;      
        padding: .5rem 1.6rem;
        font-size: 160%;
        font-weight: 600;
      }
    }
  }
  .scroll {
    align-self: flex-end;
    -webkit-align-self: flex-end;
    text-align: center;
    width: 100%;
    .btn-def {
      display: inline-block;
      height: 6rem;
      color: $color-white-texts;
      font-size: 300%;
      padding: 0 1rem 1.2rem;
      margin-bottom: 0;
      border: .15rem solid $color-white-texts;
      border-bottom: none;
      &:hover {
        i {
          transform: translateY(25px);
        }
      }
      i {
        transform: translateY(0px);
        transition: all .4s linear;
      }
    }
  }
}

@media (max-width: 761px) {
  .home-section {
    align-items: center;
    -webkit-align-items: center;
  }
}

// ----------------------------------------
.banners-section {
  background-color: $color-hp-products-bg;
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    overflow: hidden;
  }
  .banner-img {
    overflow: hidden;
    height: 550px;
    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
    }
  }
  &.open .banner.hidden {
    display: block !important;
  }
  &.open #btn-hide {
    display: block;
  }
  &.open #btn-show {
    display: none;
  }

  @media (min-width: 1030px) {
    .banner:nth-child(2n) .banner-img {
      order: 2;
    }
  }
  .center-decs {
    color: $color-products;
    padding: 2rem;
  }
  .product-box-name {
    color: $color-general;
    font-family: $font-general;
  }
  .desc-text {
    margin: 2rem auto;
    font-family: OpenSans, sans-serif;
  }
  .desc-link {
    background: none;
    border: 0.2rem solid $color-products;
    padding: 0.5rem 2.2rem;
    font-size: 100%;
    font-weight: 600;
    color: $color-general;
    text-transform: uppercase;
  }

  .show-more {
    padding: 0;
    margin: 0;
    width: 100%;
    border: none;
    text-transform: uppercase;
    color: $color-white-texts;
    background-color: $color-more-coffee;
    min-height: 0.5rem;
    .btn {
      background-color: $color-more-coffee;
      font-family: $font-general;
    }
  }
}

.about-section {
  height: 70vh;
  // or use rem instead?
  background: url($img-path + "/about.jpg") no-repeat;
  background-size: cover;
  .about-inner {
    width: 100%;    
    .about-btn {
      max-width: 100%;
      width: 30rem;
      font-size: 3rem;
      font-weight: 300;
      border: 0.2rem solid #3a342f;
      color: #3a342f;
      background-color: #f5f2f1;
      font-family: $font-general;
      transition: all .3s linear;
      &:hover {
        background-color: #3a342f;
        border: 0.2rem solid #f5f2f1;
        color: #f5f2f1;
      }
      @media (max-width: 576px) {
        width: 17rem;
        font-size: 1.8rem;
      }
    }
  }
}
