@charset "UTF-8";

@import 'variables';
@import "../../../vendor/inspishop/core/Assets/sass/framework/styles";
@import "type";
@import "button";
@import 'shop/content';
@import "shop/auth";
@import "shop/article";
@import "shop/header";
@import "shop/footer";
@import "shop/homepage";
@import "shop/cart";
@import "shop/product";
@import "shop/catalog";

html.site, html.site > body {
  height: 100%;
  width: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: $color-white-texts;
}

#admin-controls {
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#flash-messages {
  position: fixed;
  top: 5rem;
  right: 1rem;
  z-index: 100;
  border: none;
}

#wrapper {
  min-height: 100%;
  position: relative;
}

.checkbox {
  .GDPR-link {
    color: black;
    position: relative;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    transition: all .3s linear;
  }
  .GDPR-link:hover {
    color: #310303;
    .gdpr-caption {
      opacity: 1;
    }        
  }
  .gdpr-caption {
    background-color: white;
    border-radius: 2px;
    box-shadow: 2px 8px 30px 0 rgba(133, 77, 190, 0.15);
    color: black;
    min-width: 385px;
    padding: 1rem;
    line-height: 1.2;
    pointer-events: none;
    position: absolute;
    left: -10rem;
    top: -13rem;
    opacity: 0;
    transition: all .3s linear;
    z-index: 100;
    p {
      line-height: 1.35;
      color: black !important;
      margin-top: initial !important;
      margin-right: initial !important;
      margin-bottom: initial !important;
    }
  }
}

#register {
  .gdpr-caption {
    left: 15rem;
    top: -10rem;
  }
}

.doesntMatchGDPRRules {
  transition: all .3s linear;
  opacity: .75;
  pointer-events: none;
}
