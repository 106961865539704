
.article-box {
  position: relative;
  z-index: 1;
  padding: 10px;
  border: 1px solid transparent;
  background: white;
  border: 1px solid $border-light-color;
  @include transition(100ms);

  .article-box-img {
    height: 200px;
    overflow: hidden;

    table {
      width: 100%;
      height: 200px;
    }

    img {
      max-height: 200px;
      margin: 0 auto;
    }
  }

  .article-box-tags {
    $space: 5px;
    position: absolute;
    top: $space;
    left: $space;

    li {
      @include border-radius($border-radius);
      display: inline-block;
      float: left;
      text-align: center;
      clear: both;
      padding: 5px 8px;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    &.right {
      right: $space;
      li {
        float: right;
      }
    }
  }

  hr {
    border-top: 2px solid $border-light-color;
    margin: 5px 0;
  }

  .article-box-name {
    $lines: 2;
    color: $body-color;
    text-align: center;
    font-weight: 300;
    margin: 5px 0;
    max-height: $lines * 20px;
    overflow: hidden;

    table {
      width: 100%;
      height: 60px;

      h4 {
        margin: 0;
      }
    }
  }

}
#article-detail {
  @include modify-grid-gutter(15px);

  hr {
    border-top: 1px solid $border-light-color;
    margin: 5px 0;
  }

  .article-title {
    margin-top: 0;
    padding: 5px;
    margin-bottom: 10px;
  }

  .article-tags {
    li {
      @include border-radius($border-radius);
      display: inline-block;
      text-align: center;
      clear: both;
      padding: 7px 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .article-img {
    overflow: hidden;

    a {
      background: white;
      display: block;
      border: 2px solid $border-light-color;
      padding: 5px;
      margin-bottom: 15px;

      img {
        padding: 5px;
        margin: 0 auto;
        box-sizing: border-box;
        width: 100%;
      }

      p {
        margin: 0;
        color: $gray;
        font-weight: 300;
        text-align: center;
      }
    }
  }

  .article-perex {
    margin-bottom: 10px;
    padding: 0 10px;
    * {
      font-weight: 300 !important;
    }
    img {
      max-width: 100% !important;
    }
    table {
      img {
        max-width: none !important;
      }
    }
  }

  .article-desc {
    background: white;
    padding: 10px;
    margin-top: 15px;

    img {
      max-width: 100% !important;
    }

    table {
      img {
        max-width: none !important;
      }
    }
  }

}

#page .page-content p{
  font-size: 16px !important;
  background: none !important;
}