@mixin flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
    &.flag-icon-squared {
      background-image: url(#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg);
    }
  }
}