.btn-def,
.btn-def:hover,
.btn-def:focus,
.btn-def:active,
.btn-def:visited {
  background: none;
  border: none;
  color: $color-white-texts;
  text-transform: uppercase;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark:visited {
  background: none;
  border: none;
  background: $color-cart;
  color: $color-white-texts;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
}

.btn-light,
.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light:visited {
  border: none;
  background: $color-cart-light;
  color: $color-white-texts;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
}

.btn-primary,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:visited,
.btn-primary:focus {
  background-color: $color-general;
  border: none;
}

.btn-success,
.btn-success:active,
.btn-success:hover,
.btn-success:visited,
.btn-success:focus {
  background-color: $color-general;
  border: none;
}