$filter-bkg: rgb(254, 246, 236);
$btn-dark: rgb(0, 31, 42);
$c-bright: rgb(189, 204, 107);
$true: rgb(192, 204, 111);
$false: rgb(242, 38, 19);

.catalog-img {
  background: url($img-path + "/header-img1.jpg") no-repeat;  
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  .text-alpha {
    color: #fff;
    text-transform: uppercase;
    font-family: "Oswald", sans;
    font-size: 420%;
    font-weight: 300;
    margin-top: 60px;
  }
}

.products-filter {
  background-color: rgb(254, 246, 236);
  margin-top: 0%;  
  padding: 35px 10%;
  @media (min-width: 1350px) {
    padding: 35px 20%;
  }
  @media (min-width: 1250px) {
    padding: 35px 15%;
  }
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    .nav {
      .nav-item {
        margin: 0 .5rem;
      }
    }
    @media (max-width: 1160px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .nav-item {            
      border: 2px solid $btn-dark;
      background-color: transparent;
      width: 225px;
      text-align: center;
      margin: 5px 0;
      box-shadow: inset 0px 0px 0px 0px transparent;
      transition: all .3s linear;
      &:hover {
        box-shadow: inset 0px 0px 0px 3px $btn-dark;
      }
      .nav-link {
        font-size: 20px;
        padding: 10px;
        font-weight: 400;
        color: $btn-dark;
        text-transform: uppercase;        
        width: 100%;
        &.active {
          color: #fff;     
        }
      }
      &.active {
        background-color: $btn-dark;
        .nav-link {
          color: #fff;
        }        
      }
      &:not(:first-child) {
        .nav-link {
          .text-muted {
            display: none;
          }
        }
      }  
    }
  }
}

.product-count-wrapper {
  display: none;
}


.products-content {
  margin-top: 30px;
  padding: 0 10%;
  @media (min-width: 1350px) {
    padding: 0 20%;
  }
  @media (min-width: 1250px) {
    padding: 0 15%;
  }
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 455px;
  margin: 10px 0;  
  &__title {
    text-align: center;
    margin-bottom: 15px;
    a {
      color: $btn-dark;
      font-size: 18px;
      font-family: Oswald;
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__img {
    margin-bottom: 5px;
    position: relative;
    .product-rating {
      position: absolute;
      top: 10px;
      left: 10%;
    }
    .img-fluid {
      max-height: 265px;
      margin: 0 auto;
    }
  }
  &__in-stock {
    text-align: center;
    h4 {
      font-size: 14px;
      font-weight: 600;
    } 
    .true {
      color: $true;
    }
    .false {
      color: $false;
    }
  }
  &__price {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
  &__button {
    text-align: center;
    padding: 10px 15px;
    font-family: Oswald;
    .btn {
      padding: 10px 20px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 16px;
    }
    .true {
      color: #FFF;
      background-color: $btn-dark;     
      border: 1px solid transparent;
      transition: all .3s linear;
      &:hover {
        color: $btn-dark;
        border: 1px solid $btn-dark;
        background: transparent;
        cursor: pointer;        
      } 
    }
    .false {
      color: $false;      
      border: 1px solid $btn-dark;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
