.header {  
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 1rem 0 1rem;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 80;
  .header-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .common-wrapper {
      display: -webkit-box;    
      @media (max-width: 1029px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .hamburger {        
        transform: scale(.65);
        -webkit-transform: scale(.65);
        -moz-transform: scale(.65);
        -o-transform: scale(.65);
      }
      .logo {
        &__link {
          h1 {
            img {
              @media (max-width: 1029px) {
                max-height: 84px;
              }
            }
          }
        }
      }
    }
    .nav-wrapper {
      flex-direction: row;
      justify-content: space-between;
      width: 75%;
      @media (min-width: 1400px) {
        width: 70%;
      }
      @media (max-width: 1229px) {
        width: 80%;
      }
      .nav {
        display: -webkit-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 1029px) {
          flex-direction: column;      
        }
        .nav-item {
          margin: 0 1rem;
          &__link {
            color: #fff;
            text-transform: uppercase;
            font-size: $font-size-h5;
            font-family: "Oswald";
          }      
          .dropdown-menu {
            transform: translateY(.5rem);
            -webkit-transform: translateY(.5rem);
            width: auto;
          }
        }
      }
      .right-wrapper {
        display: -webkit-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        @media (max-width: 1229px) {
          align-items: center;
        }
        #header-user-info {
          display: -webkit-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 1229px) {
            flex-direction: column;
          }
          .nav-item {
            &__link {
              color: #fff;
              text-transform: uppercase;
              font-size: 1rem;
              font-family: "Oswald";  
              margin: 0 .4rem;        
            }          
          }
        }
        #header-cart-info {
          .nav-item {
            &__link {
              img {
                height: 1.9rem;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
  .nav-mobile {
    width: 100%;
    flex-direction: column;
    align-items: flex-end; 
    opacity: 0;
    transform: translateY(-2rem) translateX(14rem);
    -webkit-transform: translateY(-2rem) translateX(14rem);
    transition: all .2s linear;
    &.open {
      opacity: 1;
      transform: translateY(-2rem) translateX(0rem);
      -webkit-transform: translateY(-2rem) translateX(0rem);
    }

    .nav {
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      align-items: center;   
      background-color: #fff;     
      padding: .5rem 1rem 0 1rem;
      width: 13rem;
      .coffee {
        text-align: center;
        border-bottom: 1px solid #001f2a;
        .nav-item__link {
          &::after {
            content: ':';
          }
        }
        .links {
          display: -webkit-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;          
          padding: 0 0 .5rem 1rem;
          &__item {
            a {
              color: #001f2a;
              //text-transform: uppercase;
              font-size: 1rem;
              font-family: "Oswald";
            }
          }
        }
      }
      .nav-item {
        margin: .2rem 1rem;
        text-align: left;
        width: 100%;      
        &:first-of-type {
          border-bottom: 1px solid #001f2a;
        }
        &:last-of-type {
          border-bottom: 1px solid #001f2a;
        }
        &__link {
          color: #001f2a;
          text-transform: uppercase;
          font-size: $font-size-h5;
          font-family: "Oswald";
        }      
      }
    }
    .right-wrapper {
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #fff;
      padding-bottom: .5rem;
      width: 13rem;
      .m-l-1 {
        margin: 0 !important;
      }
      #header-user-info {
        display: -webkit-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        .nav-item {
          margin: .2rem 1rem;
          text-align: left;
          width: 100%;
          &__link {
            color: #001f2a;
            text-transform: uppercase;
            font-size: 1.25rem;
            font-family: "Oswald";              
          }          
        }
      }
      #header-cart-info {
        padding: 0 1rem;
        .nav-item {
          text-align: left;
          width: 100%;
          &__link {
            color: #001f2a;
            text-transform: uppercase;
            font-size: 1.25rem;
            font-family: "Oswald";     
            img {
              display: none;
            }
          }
        }
      }
    }
  }
}

.burger {
  width: 3.5rem;
  height: .5rem;
  position: relative;  
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: .5rem;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 18px;
    }
    &:nth-child(3) {
      top: 18px;
    }
    &:nth-child(4) {
      top: 36px;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }    
  }
}

.underline {
  display: inline;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    opacity: .9;
    z-index: -1;
    right: 0;
    width: 0;
    bottom: -5px;
    background: #FFF;
    height: 2.5px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover:after, :focus:after, :active:after {     
    left: 0;
    right: auto;
    width: 100%;        
  }
}

.nav-desktop {
  display: flex;
  @media (max-width: 1029px) {
    display: none;
  }
}

.nav-mobile {
  display: none;
  @media (max-width: 1029px) {
    display: flex;
  }
}
