/* ==========================================================================
   Rating Block
   ========================================================================== */

.rating {
  $hover-color: #e3cf7a;
  $blur-color: #999;
  unicode-bidi: bidi-override;
  text-align: left;
  height: 20px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.hover:hover {
    .star:before {
      content: "\f006" !important;
      color: $blur-color !important;
    }
  }

  .stars {
    display: inline-block;
    direction: rtl;
    padding-left: 4px;
    vertical-align: top;
    margin-left: 5px;

    .star {
      //      @include fa-icon;
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      display: inline-block;
      font-size: 20px;
      line-height: 20px;
      vertical-align: middle;
      margin-left: -5px;
      width: 22px;
      height: 20px;

      &:before {
        text-align: left;
        content: "\f006";
        color: $blur-color;
      }

      &.half:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        content: "\f005";
        color: $blur-color;
      }

      &.half:before {
        content: "\f123";
        color: $hover-color;
      }

      &.full:before {
        content: "\f005";
        color: $hover-color;
      }
    }
  }

  &.hover {
    .star {
      cursor: pointer;
      &:hover:before, &:hover ~ .star:before {
        content: "\f005" !important;
        color: $hover-color !important;
      }
    }
  }
  .text {
    display: inline-block;
    line-height: 20px;
    vertical-align: top;
    margin-left: 5px;
    color: $blur-color;
  }

  &.small {
    .stars {
      .star {
        font-size: 16px;
        line-height: 16px;
        width: 17px;
        height: 16px;
      }
    }
    .text {
      display: none;
    }
  }
}