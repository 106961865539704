$remove-color: #888 !default;
$remove-hover-color: #555 !default;
$remove-width: 20px !default;

$selection-color: $input-border-focus !default;

$container-height: $input-height + 0.125rem !default;

$dropdown-z-index: 39 !default;

$results-max-height: 200px !default;
$results-nested-padding: 20px !default;

.select2-container {
  z-index: 30;
  &.select2-container--open {
    z-index: 1051;
  }
}
.select2-hidden-accessible {
  height: auto !important;
}
.select2-container.select2-container--default {
  .select2-selection--single {
    background: $input-bg;
    height: $container-height;
    border: $input-btn-border-width solid $input-border-color;
    border-radius: $border-radius;
    outline: 0;

    &:focus {
      border: $input-btn-border-width solid $input-border-focus;
      outline: 0;
      //-webkit-box-shadow : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
      //box-shadow         : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
      .select2-selection__arrow::after {
        color: $input-border-focus;
      }
    }

    .select2-selection__rendered {
      color: #444;
      line-height: $container-height;
      padding: 0 0.75rem;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }

    .select2-selection__placeholder {
      color: #999;
    }

    .select2-selection__arrow {
      background: none;
      border: none;

      &:after {
        content: "\f107";
        position: absolute;
        z-index: 0;
        top: 0.375rem;
        right: 0.7rem;
        font-family: FontAwesome;
        color: #7e7e7e;
        font-size: 1.5rem;
        line-height: 1;
      }
      b {
        display: none;
      }
    }
  }

  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__clear {
        float: left;
      }

      .select2-selection__arrow {
        border: none;
        border-right: $input-btn-border-width solid $input-border-color;
        border-radius: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        left: $input-btn-border-width;
        right: auto;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      border: $input-btn-border-width solid $input-border-focus;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      .select2-selection__arrow {
        background: none;
        border: none;

        &:after {
          color: $input-border-focus
        }
      }
    }

    &.select2-container--above {
      .select2-selection--single {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {
      .select2-selection--single {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .select2-selection--multiple {
    background-color: white;
    border: $input-btn-border-width solid $input-border-color !important;
    border-radius: $border-radius;
    min-height: $container-height;
    cursor: text;
    outline: 0;

    &:focus {
      border: $input-btn-border-width solid $input-border-focus;
      outline: 0;
      //-webkit-box-shadow : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
      //box-shadow         : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }

    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      //padding: 0.375rem 0.75rem;
      //padding    : 0 5px;
    }

    .select2-selection__clear {
      display: none;
    }

    .select2-selection__choice {
      float: none;
      display: block;
      background-color: #e4e4e4;
      border: $input-btn-border-width solid $input-border-color;
      border-radius: $border-radius;
      cursor: default;
      margin-right: 0px;
      margin-top: 5px;
      padding: 3px 10px;
    }

    .select2-selection__choice__remove {
      color: $remove-color;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: -2px;
      float: right;
      font-size: 24px;
      line-height: 20px;

      &:hover {
        color: $remove-hover-color;
      }
    }
  }

  &[dir="rtl"] {
    .select2-selection--multiple {
      .select2-selection__choice {
        float: right;
      }

      .select2-selection__choice {
        margin-left: 5px;
        margin-right: auto;
      }

      .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: auto;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--multiple {
      border: $input-btn-border-width solid $input-border-focus;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &.select2-container--above {
      .select2-selection--multiple {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {
      .select2-selection--multiple {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .select2-search--dropdown {
    .select2-search__field {
      border: $input-btn-border-width solid $input-border-color;
      outline: 0;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      min-width: 200px;
      outline: 0;
    }
  }

  .select2-dropdown {
    @include border-radius($border-radius);
    background-color: $input-bg;
    border: $input-btn-border-width solid transparent;
  }

  .select2-dropdown--above {
    @include border-top-radius($border-radius);
    border-bottom: none;
  }

  .select2-dropdown--below {
    @include border-bottom-radius($border-radius);
    border-top: none;
  }

  .select2-results > .select2-results__options {
    max-height: $results-max-height;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      display: none;
      color: $gray;
    }

    &.loading-results {
      display: block !important;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $input-border-focus;
    color: $input-bg;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: $input-border-focus;
  }

  &.select2-container--disabled {
    .select2-selection--single {
      background-color: $input-bg-disabled;
      cursor: not-allowed;
      border-color: $input-border-color !important;
      .select2-selection__arrow::after {
        color: $input-border-color !important;
      }
    }
  }
}
.required {
  .select2-selection__arrow {
    &:after {
      right: 1.3rem !important;
    }
  }
}
.has-danger {
  .select2-container.select2-container--default {
    .select2-selection {
      border-color: #ce5b5b;
    }
    &.select2-container--open {
      .select2-selection {
        border-color: $input-border-focus;
      }
    }
  }
}



