// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// http://useiconic.com/open

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.
.form-relative {
  position: relative;
  &.required {
    &:after {
      content: '*';
      font-size: 1.3rem;
      line-height: 1;
      font-weight: 600;
      color: $state-danger-text;
      position: absolute;
      top: 0.75rem;
      right: 0.5rem;
      background: none;
      z-index: 3;
    }
  }
}
.has-danger {
  .required:after {
    display: none;
  }
}
.form-error {
  @include border-radius(1.5rem $input-border-radius $input-border-radius 1.5rem);
  position: absolute;
  color: $state-danger-text;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, .75);
  padding: $input-padding-y;
  padding-left: $input-padding-x;
  margin: 1px;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  z-index: 3;
}
.form-control.form-control-sm + .form-error {
  padding: $input-padding-y-sm;
  padding-left: $input-padding-x-sm;
  font-size: $font-size-sm;
}
.c-input {
  position: relative;
  display: inline;
  padding-left: 1.5rem;
  color: #555;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
    opacity: 0;

    &:checked ~ .c-indicator {
      color: $input-bg;
      border-color: $input-border-focus;
      background-color: $input-border-focus;
      @include box-shadow(none);
    }

    &:focus:not(:checked) ~ .c-indicator {
      // the mixin is not used here to make sure there is feedback
      border-color: $input-border-focus;
    }

    &:focus:checked ~ .c-indicator {
      // the mixin is not used here to make sure there is feedback
      box-shadow: 0 0 0 1px #fff, 0 0 0 2.3px $input-border-focus;
    }

    &:active ~ .c-indicator {
      color: $input-bg;
      background-color: $input-border-focus;
      @include box-shadow(none);
    }
  }

  + .c-input {
    margin-left: 1rem;
  }
}
// Custom indicator
//
// Generates a shadow element to create our makeshift checkbox/radio background.

.c-indicator {
  position: absolute;
  top: 50%;
  margin-top: -0.5022rem;
  left: 0;
  display: block;
  width: 1.045rem;
  height: 1.045rem;
  font-size: 65%;
  line-height: 1rem;
  color: #eee;
  text-align: center;
  user-select: none;
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  border: 1px solid $border-color;
  @include box-shadow(inset 0 .125rem .125rem rgba(0, 0, 0, .1));
}
// Checkboxes
//
// Tweak just a few things for checkboxes.

.c-checkbox {
  .c-indicator {
    border-radius: $checkbox-border-radius;
  }

  input:checked ~ .c-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  }

  input:indeterminate ~ .c-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K);
    color: $input-bg;
    border-color: $input-border-focus;
    background-color: $input-border-focus;
    @include box-shadow(none);
  }

  input:disabled ~ .c-indicator {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgNDIuMjQxIDQyLjI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Mi4yNDEgNDIuMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSIjYzRjNGM0IiBkPSJNNDAuOTIyLDQwLjkyMmMtMS43NTgsMS43NTgtNC42MDYsMS43NTgtNi4zNjQsMEwxLjMxOCw3LjY4MmMtMS43NTctMS43NTctMS43NTctNC42MDYsMC02LjM2NGwwLDAKCWMxLjc1Ny0xLjc1OCw0LjYwNi0xLjc1OCw2LjM2NCwwbDMzLjI0LDMzLjIzOUM0Mi42OCwzNi4zMTYsNDIuNjc5LDM5LjE2NSw0MC45MjIsNDAuOTIyTDQwLjkyMiw0MC45MjJ6Ii8+CjxwYXRoIGZpbGw9IiNjNGM0YzQiIGQ9Ik00MC45MjIsMS4zMThjMS43NTgsMS43NTcsMS43NTgsNC42MDYsMCw2LjM2NGwtMzMuMjQsMzMuMjRjLTEuNzU4LDEuNzU4LTQuNjA2LDEuNzU4LTYuMzY0LDBsMCwwCgljLTEuNzU4LTEuNzU3LTEuNzU4LTQuNjA1LDAtNi4zNjRMMzQuNTU4LDEuMzE4QzM2LjMxNi0wLjQzOSwzOS4xNjYtMC40MzksNDAuOTIyLDEuMzE4TDQwLjkyMiwxLjMxOHoiLz4KPC9zdmc+Cg==);
    @include box-shadow(none);
  }
}
// Radios
//
// Tweak just a few things for radios.

.c-radio {
  .c-indicator {
    border-radius: 50%;
  }

  input:checked ~ .c-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
  }

  input:disabled ~ .c-indicator {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgNDIuMjQxIDQyLjI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Mi4yNDEgNDIuMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSIjYzRjNGM0IiBkPSJNNDAuOTIyLDQwLjkyMmMtMS43NTgsMS43NTgtNC42MDYsMS43NTgtNi4zNjQsMEwxLjMxOCw3LjY4MmMtMS43NTctMS43NTctMS43NTctNC42MDYsMC02LjM2NGwwLDAKCWMxLjc1Ny0xLjc1OCw0LjYwNi0xLjc1OCw2LjM2NCwwbDMzLjI0LDMzLjIzOUM0Mi42OCwzNi4zMTYsNDIuNjc5LDM5LjE2NSw0MC45MjIsNDAuOTIyTDQwLjkyMiw0MC45MjJ6Ii8+CjxwYXRoIGZpbGw9IiNjNGM0YzQiIGQ9Ik00MC45MjIsMS4zMThjMS43NTgsMS43NTcsMS43NTgsNC42MDYsMCw2LjM2NGwtMzMuMjQsMzMuMjRjLTEuNzU4LDEuNzU4LTQuNjA2LDEuNzU4LTYuMzY0LDBsMCwwCgljLTEuNzU4LTEuNzU3LTEuNzU4LTQuNjA1LDAtNi4zNjRMMzQuNTU4LDEuMzE4QzM2LjMxNi0wLjQzOSwzOS4xNjYtMC40MzksNDAuOTIyLDEuMzE4TDQwLjkyMiwxLjMxOHoiLz4KPC9zdmc+Cg==);
    @include box-shadow(none);
  }

  input[data-indeterminate='true']:indeterminate ~ .c-indicator {
    color: $input-bg;
    border-color: $input-border-focus;
    background-color: $input-border-focus;
    @include box-shadow(none);
  }
}
// Layout options
//
// By default radios and checkboxes are `inline-block` with no additional spacing
// set. Use these optional classes to tweak the layout.

.c-inputs-stacked {
  .c-input {
    display: inline;

    &::after {
      display: block;
      margin-bottom: .25rem;
      content: "";
    }

    + .c-input {
      margin-left: 0;
    }
  }
}
// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// http://primercss.io.
//
// Includes IE9-specific hacks (noted by ` \9`).

.select {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  margin: 0;

  select {
    padding-right: 1.8rem;
    position: relative;
    background: none;
    // Use vendor prefixes as `appearance` isn't part of the CSS spec.
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    z-index: 2;
    // Hides the default caret in IE11
    &::-ms-expand {
      opacity: 0;
    }
  }

  .select-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    @extend %form-control;;

    &::after {
      content: "\f107";
      position: absolute;
      z-index: 0;
      top: auto;
      right: 0.7rem;
      font-family: FontAwesome;
      color: #7e7e7e;
      font-size: 1.5rem;
      line-height: 1;
    }
  }
}
.select select.form-control-sm + .select-custom {
  &::after {
    font-size: 1.3rem;
    line-height: 0.9;
    right: 0.6rem;
  }
}
.select select:focus + .select-custom {
  border-color: $input-border-focus;
  outline: none;
  $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $input-box-shadow-focus;
  @include box-shadow($shadow);
  &::after {
    color: $input-border-focus;
  }
}
.required {
  .select-custom {
    &::after {
      right: 1.3rem;
    }
  }
}
// File
//
// Custom file input.
.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.file input {
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}
.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  @extend .form-control;
  overflow: hidden;
  padding-right: 2rem;
  text-overflow: ellipsis;

  .text {
    white-space: nowrap;
  }

  &::after {
    position: absolute;
    z-index: 0;
    top: 0.35rem;
    right: 0.7rem;
    content: '\f093';
    font-family: FontAwesome;
    color: #7e7e7e;
  }

  &.has-file {
    padding-right: 3.4rem;

    .close {
      position: absolute;
      right: $input-padding-y;
      top: $input-padding-y;
      left: initial;
      z-index: 9;

      &:hover {
        color: $brand-danger;
        opacity: 1;
      }
    }

    &::after {
      right: 2.2rem;
    }

    &.has-image {
      padding-left: $input-padding-y*2 + $form-icon-height;

      .img-icon {
        position: absolute;
        top: $input-padding-y;
        left: $input-padding-y;
        display: inline-block;
        outline: none;
        z-index: 9;
        vertical-align: top;

        img {
          display: block;
          @include border-radius($border-radius);
          height: $form-icon-height;
          width: $form-icon-height;
        }
      }
    }
  }

  &.disabled {
    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(238, 238, 238, .7);
    }
    a.close {
      z-index: 12;
      &:hover {
        color: $brand-success;
      }
    }
  }
}
// Focus state
.file input:focus + .file-custom, .file-custom.active {
  border-color: $input-border-focus;
  outline: none;
  $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $input-box-shadow-focus;
  @include box-shadow($shadow);
  &::after {
    color: $input-border-focus;
  }
}
.file input:disabled + .file-custom {
  background: $input-bg-disabled;
  cursor: not-allowed;
}
.list-group.c-list-group {
  list-style: none;
  margin-bottom: $spacer-y;
  @include border-radius($border-radius);

  .list-group-item {
    @include border-radius($border-radius);
    border-color: $border-color;
    padding: $input-padding-y $input-padding-x;
    padding-right: 1.7rem;

    .img-icon {
      position: absolute;
      top: $input-padding-y;
      left: $input-padding-y;
      display: inline-block;
      outline: none;
      z-index: 9;
      vertical-align: top;

      img {
        display: block;
        @include border-radius($border-radius);
        height: $form-icon-height;
        width: $form-icon-height;
      }
    }

    input.desc {
      display: block;
      border: none;
      background: none;
      outline: none;
      padding: 0;
      width: 100%;
    }

    .close {
      position: absolute;
      right: $input-padding-y;
      top: $input-padding-y * .85;
      left: initial;
      z-index: 3;

      &:hover {
        color: $brand-danger;
        opacity: 1;
      }
    }

    &.disabled {
      &::before {
        content: '';
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(238, 238, 238, .7);
      }
      &::after {
        //content: 'Delete';
        position: absolute;
        z-index: 11;
        top: 50%;
        left: 0;
        width: 100%;
        margin-top: -0.5em;
        line-height: 1;
        color: $brand-danger;
        font-weight: 200;
        font-size: 18px;
        text-align: center;
      }
      a.close {
        z-index: 12;
        &:hover {
          color: $brand-success;
        }
      }
    }
  }

  &.files {
    .list-group-item {
      padding-left: $input-padding-y*2 + $form-icon-height;
    }
  }

  &.sortable {
    .list-group-item {
      padding-right: 3.4rem;
    }

    &:after {
      content: ' ';
      display: block;
      overflow: hidden;
    }
    .handle {
      position: absolute;
      top: $input-padding-y;
      right: 2.05rem;
      padding: 0 .2rem;
      cursor: ns-resize;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      float: right;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .2;
      &:hover {
        color: $body-color;
        opacity: 1;
      }
    }
    .sortable-dragging {
      cursor: -webkit-grabbing;
      cursor: -webkit-grabbing;
    }
    .sortable-placeholder {
      @include border-radius($border-radius);
      display: block;
      border-color: $border-color;
      border-style: dashed;
      border-width: 2px;
      margin: .1rem 0;
      padding: .2rem;
      background: none;
      height: .6rem + ($font-size-base * $line-height);
    }
  }
}
.c-enabler {
  background-color: $input-bg;
  .c-checkbox {
    padding-left: 1rem;
  }

  .checkbox {
    padding: 0;
  }

  &.active {
    background-color: $input-group-addon-bg
  }
}
.c-enabler-input {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
  border: $input-btn-border-width solid $input-border-color;
  padding: 0;

  .form-control, .select-custom, .select2.select2-container .select2-selection {
    border-color: transparent !important;
  }

  .select2.select2-container.select2-container--open .select2-selection {
    border-left-color: $input-border-focus !important;
    border-right-color: $input-border-focus !important;
  }

  .select2.select2-container.select2-container--above .select2-selection {
    border-bottom-color: $input-border-focus !important;
  }

  .select2.select2-container.select2-container--below .select2-selection {
    border-top-color: $input-border-focus !important;
  }

  .c-list-group {
    margin: 0;
  }

  .list-group-item {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    margin: 0;
  }

  &.disabled {
    cursor: not-allowed !important;
    background: $input-bg-disabled;

    .list-group-item {
      background: $input-bg-disabled;
      cursor: not-allowed !important;
    }

    .checkbox label {
      cursor: not-allowed !important;
    }
    input {
      cursor: not-allowed !important;
    }
  }
}
.has-danger {
  .c-enabler-input {
    border-color: $brand-danger;
  }
}
.c-enabler-checkbox {
  padding-left: $input-padding-x;
  padding-right: $input-padding-x;
}
.btn-label-disable, .btn-label-checkbox {
  margin: 0;

  .form-relative, .form-group, .form-control {
    display: none;
  }
}
.input-number-wrapper {
  position: relative;
  padding: 0;

  .input-number {
    border: none;
    border-right: 1px solid $input-border-color;
    width: calc(100% - 25px);
    text-align: center;
  }

  .input-number-control {
    width: 25px;
    height: 50%;
    position: absolute;
    right: 0;
    background: none;
    border: $input-border-color;
    padding: 0;
    text-align: center;
    line-height: normal;

    &:hover {
      background: $gray-lighter;
    }

    &.plus {
      top: 0;
      border-bottom: 1px solid $input-border-color;
    }
    &.minus {
      top: 50%;
    }
  }
}
