// Responsive images (ensure images don't scale beyond their parents)
//
// This is purposefully opt-in via an explicit class rather than being the default for all `<img>`s.
// We previously tried the "images are responsive by default" approach in Bootstrap v2,
// and abandoned it in Bootstrap v3 because it breaks lots of third-party widgets (including Google Maps)
// which weren't expecting the images within themselves to be involuntarily resized.
// See also https://github.com/twbs/bootstrap/issues/18178
.img-fluid {
  @include img-fluid();
}
// Rounded corners
.img-rounded {
  @include border-radius($border-radius-lg);
}
// Image thumbnails
.img-thumbnail {
  padding: $thumbnail-padding;
  line-height: $line-height;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  border-radius: $thumbnail-border-radius;
  transition: all .2s ease-in-out;
  @include box-shadow(0 1px 2px rgba(0, 0, 0, .075));

  // Keep them at most 100% wide
  @include img-fluid(inline-block);
}
// Perfect circle
.img-circle {
  border-radius: 50%;
}
//
// Figures
//

.figure {
  // Ensures the caption's text aligns with the image.
  display: inline-block;
}
.figure-img {
  margin-bottom: ($spacer-y / 2);
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: $gray-light;
}
