// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.

// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark: #373a3c !default;
$gray: #55595c !default;
$gray-light: #818a91 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;

$brand-primary: #1a627a !default;
$brand-success: #009e84 !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #ce5b5b !default;

$facebook: #3b5998 !default;
$twitter: #55acee !default;
$google-plus: #dd4b39 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex: false !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: false !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
        0: (
                x: 0,
                y: 0
        ),
        1: (
                x: $spacer-x,
                y: $spacer-y
        ),
        2: (
                x: ($spacer-x * 1.5),
                y: ($spacer-y * 1.5)
        ),
        3: (
                x: ($spacer-x * 3),
                y: ($spacer-y * 3)
        )
) !default;
$border-width: 1px !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff !default;
$body-color: $gray-dark !default;
$border-color: #ccc !default;
$border-light-color: $gray-lighter;

// Links
//
// Style anchor elements.

$link-color: $brand-primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 544px,
  // Medium screen / tablet
        md: 768px,
  // Large screen / desktop
        lg: 992px,
  // Extra large screen / wide desktop
        xl: 1200px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 576px,
        md: 720px,
        lg: 940px,
        xl: 1140px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.875rem !default; // 30px

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px !default;

$font-size-base: 1rem !default;
$font-size-xl: 1.75rem !default;
$font-size-lg: 1.25rem !default;
$font-size-sm: .875rem !default;
$font-size-xs: .875rem !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;

$line-height: 1.5 !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

$lead-font-size: 1.25rem !default;
$lead-font-weight: 300 !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color: $gray-light !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;

$hr-border-color: $border-color !default;
$hr-border-width: $border-width !default;

$dt-font-weight: bold !default;

$nested-kbd-font-weight: bold !default;

$list-inline-padding: 5px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-xl: (4 / 3) !default;
$line-height-lg: (4 / 3) !default;
$line-height-sm: 1.5 !default;
$line-height-xs: 1.8 !default;

$border-radius: 0rem !default;
$border-radius-lg: 0rem !default;
$border-radius-sm: 0rem !default;

$component-active-color: #fff !default;
$component-active-bg: $brand-primary !default;

$caret-width: .3em !default;
$caret-width-lg: $caret-width !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: .75rem !default;
$table-sm-cell-padding: .3rem !default;

$table-bg: transparent !default;
$table-bg-accent: #f9f9f9 !default;
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x: 1rem !default;
$btn-padding-y: .375rem !default;
$btn-font-weight: normal !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-secondary-color: $gray-dark !default;
$btn-secondary-bg: #fff !default;
$btn-secondary-border: #ccc !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $btn-info-bg !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $btn-success-bg !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $btn-warning-bg !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $btn-danger-bg !default;

$btn-link-disabled-color: $gray-light !default;

$btn-padding-x-xl: 1.25rem !default;
$btn-padding-y-xl: .75rem !default;

$btn-padding-x-lg: 1.25rem !default;
$btn-padding-y-lg: .75rem !default;

$btn-padding-x-sm: .50rem !default;
$btn-padding-y-sm: .25rem !default;

$btn-padding-x-xs: .3rem !default;
$btn-padding-y-xs: .1rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

// Forms

$input-padding-x: .75rem !default;
$input-padding-y: .375rem !default;

$input-bg: #fff !default;
$input-bg-disabled: $gray-lighter !default;

$input-color: $gray !default;
$input-border-color: $border-color !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;
$checkbox-border-radius: 0 !default;

$input-border-focus: #66afe9 !default;
$input-box-shadow-focus: rgba(102, 175, 233, .6) !default;

$input-color-placeholder: #999 !default;

$input-padding-x-sm: .75rem !default;
$input-padding-y-sm: .275rem !default;

$input-padding-x-lg: 1.25rem !default;
$input-padding-y-lg: .75rem !default;

$input-height: (($font-size-base * $line-height) + ($input-padding-y * 2)) !default;
$input-height-lg: (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

$form-group-margin-bottom: $spacer-y !default;

$input-group-addon-bg: $gray-lighter !default;
$input-group-addon-border-color: $input-border-color !default;

$cursor-disabled: not-allowed !default;

// Form validation icons
$form-icon-success: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==" !default;
$form-icon-warning: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+" !default;
$form-icon-danger: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=" !default;
$form-icon-height: 1.625rem;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg: #fff !default;
$dropdown-border-color: rgba(0, 0, 0, .15) !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: #e5e5e5 !default;

$dropdown-link-color: $gray-dark !default;
$dropdown-link-hover-color: darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg: #f5f5f5 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-light !default;

$dropdown-header-color: $gray-light !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-navbar-sticky: 1030 !default;
$zindex-modal-bg: 1040 !default;
$zindex-modal: 1050 !default;

// Navbar

$navbar-border-radius: $border-radius !default;
$navbar-padding-horizontal: $spacer !default;
$navbar-padding-vertical: ($spacer / 2) !default;

$navbar-dark-color: rgba(255, 255, 255, .5) !default;
$navbar-dark-hover-color: rgba(255, 255, 255, .75) !default;
$navbar-dark-active-color: rgba(255, 255, 255, 1) !default;
$navbar-dark-disabled-color: rgba(255, 255, 255, .25) !default;

$navbar-light-color: rgba(0, 0, 0, .3) !default;
$navbar-light-hover-color: rgba(0, 0, 0, .6) !default;
$navbar-light-active-color: rgba(0, 0, 0, .8) !default;
$navbar-light-disabled-color: rgba(0, 0, 0, .15) !default;

// Navs

$nav-link-padding: .5em 1em !default;
$nav-link-hover-bg: $gray-lighter !default;

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

$nav-tabs-border-color: $border-color !default;

$nav-tabs-link-border-width: $border-width !default;
$nav-tabs-link-hover-border-color: $border-light-color !default;

$nav-tabs-active-link-hover-bg: $body-bg !default;
$nav-tabs-active-link-hover-color: $gray !default;
$nav-tabs-active-link-hover-border-color: $border-color !default;

$nav-tabs-justified-link-border-color: $border-color !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-active-link-hover-bg: $component-active-bg !default;
$nav-pills-active-link-hover-color: $component-active-color !default;

// Pagination

$pagination-padding-x: .75rem !default;
$pagination-padding-y: .3rem !default;
$pagination-padding-x-sm: .75rem !default;
$pagination-padding-y-sm: .275rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-padding-y-lg: .75rem !default;

$pagination-color: $link-color !default;
$pagination-bg: #fff !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: #ddd !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-border: #ddd !default;

$pagination-active-color: #fff !default;
$pagination-active-bg: $brand-primary !default;
$pagination-active-border: $brand-primary !default;

$pagination-disabled-color: $gray-light !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: #ddd !default;

// Pager

$pager-bg: $pagination-bg !default;
$pager-border-width: $border-width !default;
$pager-border-color: $pagination-border-color !default;
$pager-border-radius: 15px !default;

$pager-hover-bg: $pagination-hover-bg !default;

$pager-active-bg: $pagination-active-bg !default;
$pager-active-color: $pagination-active-color !default;

$pager-disabled-color: $pagination-disabled-color !default;

// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-bg: $gray-lighter !default;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: darken($state-success-bg, 5%) !default;

$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: darken($state-info-bg, 7%) !default;

$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: darken($state-warning-bg, 5%) !default;

$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: darken($state-danger-bg, 5%) !default;

// Cards
$card-spacer-x: 1.25rem !default;
$card-spacer-y: .75rem !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius !default;
$card-border-color: #e5e5e5 !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg: #f5f5f5 !default;
$card-bg: #fff !default;

$card-link-hover-color: #fff !default;

// Tooltips

$tooltip-max-width: 200px !default;
$tooltip-color: #fff !default;
$tooltip-bg: #000 !default;
$tooltip-opacity: .9 !default;

$tooltip-arrow-width: 5px !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Popovers

$popover-bg: #fff !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba(0, 0, 0, .2) !default;

$popover-title-bg: darken($popover-bg, 3%) !default;

$popover-arrow-width: 10px !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Labels

$label-default-bg: $gray-light !default;
$label-primary-bg: $brand-primary !default;
$label-success-bg: $brand-success !default;
$label-info-bg: $brand-info !default;
$label-warning-bg: $brand-warning !default;
$label-danger-bg: $brand-danger !default;

$label-color: #fff !default;
$label-link-hover-color: #fff !default;
$label-font-weight: bold !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 15px !default;

$modal-title-padding: 15px !default;
$modal-title-line-height: $line-height !default;

$modal-content-bg: #fff !default;
$modal-content-border-color: rgba(0, 0, 0, .2) !default;

$modal-backdrop-bg: #000 !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: #e5e5e5 !default;
$modal-footer-border-color: $modal-header-border-color !default;

$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding: 15px !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: bold !default;
$alert-border-width: $border-width !default;

$alert-success-bg: $state-success-bg !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: $state-success-border !default;

$alert-info-bg: $state-info-bg !default;
$alert-info-text: $state-info-text !default;
$alert-info-border: $state-info-border !default;

$alert-warning-bg: $state-warning-bg !default;
$alert-warning-text: $state-warning-text !default;
$alert-warning-border: $state-warning-border !default;

$alert-danger-bg: $state-danger-bg !default;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: $state-danger-border !default;

// Progress bars

$progress-bg: #f5f5f5 !default;
$progress-bar-color: #fff !default;
$progress-border-radius: $border-radius !default;

$progress-bar-bg: $brand-primary !default;
$progress-bar-success-bg: $brand-success !default;
$progress-bar-warning-bg: $brand-warning !default;
$progress-bar-danger-bg: $brand-danger !default;
$progress-bar-info-bg: $brand-info !default;

// List group

$list-group-bg: #fff !default;
$list-group-border-color: #ddd !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-hover-bg: #f5f5f5 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border: $list-group-active-bg !default;
$list-group-active-text-color: lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color: $gray-light !default;
$list-group-disabled-bg: $gray-lighter !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

// Image thumbnails

$thumbnail-padding: .25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: #ddd !default;
$thumbnail-border-radius: $border-radius !default;

// Breadcrumbs

$breadcrumb-padding-vertical: .5rem !default;
$breadcrumb-padding-horizontal: .75rem !default;

$breadcrumb-bg: $gray-lighter !default;
$breadcrumb-divider-color: $gray-light !default;
$breadcrumb-active-color: $gray-light !default;
$breadcrumb-font: FontAwesome !default;
$breadcrumb-divider: "\f105" !default;

// Carousel

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6) !default;

$carousel-control-color: #fff !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: .5 !default;
$carousel-control-font-size: 20px !default;

$carousel-indicator-active-bg: #fff !default;
$carousel-indicator-border-color: #fff !default;

$carousel-caption-color: #fff !default;

// Close

$close-font-weight: bold !default;
$close-color: #000 !default;
$close-text-shadow: 0 1px 0 #fff !default;

// Code

$code-color: #bd4147 !default;
$code-bg: #f7f7f9 !default;

$kbd-color: #fff !default;
$kbd-bg: #333 !default;

$pre-bg: #f7f7f9 !default;
$pre-color: $gray-dark !default;
$pre-border-color: #ccc !default;
$pre-scrollable-max-height: 340px !default;

// Flags

$flag-icon-css-path: '/img/flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;
