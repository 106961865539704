#product-detail {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;

  & > .row {
    padding: 0 4rem 0 0;
    margin: 3rem auto;
  }
  .col-sm-6 {
    padding-left: 0;
  }

  .product-image img {
    width: 50%;
    margin: 0 auto;    
  }

  .info {
    padding: 0;
    font-family: $font-secondary;

    .product-desc {
      margin-bottom: 2rem;
      font-size: 105%;
    }
    th {
      width: 12rem;
    }
    tr:first-child {
      td, th {
        border-top: none;
      }
    }
    td, th {
      font-family: $font-general;
      padding: 0.5rem 0;
    }
    th {
      color: $color-detail-th;
      font-size: 115%;
    }
    td {
      font-size: 105%;
    }
  }
  .input-number-wrapper {
    width: 6rem;
    margin-left: 0.5rem;
  }
  .input-number {
    height: calc(3.6rem - 2px);
    color: $color-general;
    font-size: 1.5rem;
  }
  .table {
    margin-bottom: 2rem;
  }

  .product-variant-tags {
    display: none;
  }

  .add-container {
    position: relative;
    width: 100%;
    height: 12rem;
  }

  .product-select-variant {
    height: 3.6rem;

    table {
      td {
        padding: 0;
      }
      height: 3.6rem;
      * {
        height: 3.6rem;

      }
      width: 100%;
      font-family: $font-general;
      margin: 0;
      padding: 0.5rem 1rem;
      .form-control {
        font-size: 1.5rem;
      }
    }
    .select-custom {
      padding: 0;
    }
    .select-custom:after {
      vertical-align: middle;
      position: relative;
      line-height: 3.6rem;
      float: right;
      content: "\f0d7";
      color: $color-arrows;
      font-size: 200%;
    }

  }

  .product-variant-box {
    height: 3.6rem;
    margin-left: 2rem;
  }
  .price-stock-wr {
    padding-left: 8rem;
  }

  .add-to-cart {
    height: 3.6rem;

    .quantity {
      .form-control {
        font-family: $font-general;
        height: 100%;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        width: auto;
        display: inline-block;
      }
      .ui-spinner {
        width: 5rem;
        height: 100%;
        border-radius: 0;
        border-color: $color-specline;
      }
      .spinner {
        height: 100%;
        margin: 0;
        border: none;
      }
      .ui-spinner-button {
        margin-right: 0.5rem;
        border: none;
        background: none;
      }
      //.ui-icon {
      //  content: "\f0d7" !important;
      //  color: $color-arrows;
      //  font-size: 200%;
      //}
    }

    .add {
      height: 100%;
      border: none;
      background-color: $color-general;
      border: 1px solid transparent;
      color: $color-white-texts;
      font-size: 120%;
      text-transform: uppercase;
      font-family: Oswald, sans-serif;
      width: auto;
      padding: 10px 25px;
      transition: all .3s linear;
      &:hover {
        color: $color-general;
        background-color: transparent;
        border: 1px solid $color-general; 
      }

      .img-fluid {
        height: 100%;
        display: inline-block;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        margin-left: 0.5rem;
      }
    }
  }
  .pick {
    vertical-align: middle;
    font-family: OpenSans, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-size: 120%;
    line-height: 3.6rem;
    height: 3.6rem;
  }
  .price-container {
    position: absolute;
    left: 0;
    bottom: 0;
    font-family: Oswald, sans-serif;
    width: 100%;
    display: block;
    .price {
      margin-top: 1rem;
      .amount {
        font-size: 160%;
      }
      .vat {
        font-weight: 300;
      }
    }
    .price {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      .amount {
        height: 3.6rem;
        line-height: 3.6rem;
      }
      .pick {
        margin-right: 1rem;
      }
    }

    .product-stock {
      padding-top: 2rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 3.6rem;
      .stock-label {
        height: 3.6rem;
        line-height: 3.6rem;
        color: $color-onstock;
        font-size: 120%;
      }
      .stock-label + .stock-label {
        padding-left: 0.5rem;
      }
      .pick {
        margin-right: 1rem;
      }
    }
  }

  .hidden {
    display: none;
  }

  label.control-label {
    display: none;
    visibility: hidden;
  }

}

.detailed-desc-reviews {

  .nav-tabs {
    height: 3rem;
    border: none;
    margin: 0;
    padding: 0;
    border-bottom: 3px solid $color-general;
    font-size: 120%;
    @media (max-width: 576px) {
      height: 6rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .nav-item:first-child {
      margin-left: 6rem;
    }
    @media (max-width: 47.9em) {
      .nav-item:first-child {
        margin-left: 0;
      }
    }
    .nav-link,
    .nav-link:focus,
    .nav-link:visited,
    .nav-link:hover {
      height: 3rem;
      border: none;
      margin: 0 auto -3px;
      color: $color-general;
      font-family: OpenSans, sans-serif;
      font-weight: 700;
      @media (max-width: 576px) {
        height: 6rem;
        text-align: center;
      }
    }
    .active,
    .active:focus,
    .active:visited,
    .active:hover {
      border-left: 3px solid $color-general;
      border-right: 3px solid $color-general;
      border-top: 3px solid $color-general;
      border-bottom: none;
      background-color: $color-white-texts;
      color: $color-detail-th;
      z-index: -1;
    }
  }
  #reviews {
    .avg-rating {
      margin-top: 1rem;
      margin-bottom: 2rem;
      .rating {
        height: 2rem;
        font-size: 150%;
        text-align: center;
        .star {
          font-size: 110%;
          margin: 0 0 0 0.3rem;
        }
        .text {
          margin-top: 0.3rem;
          height: 2rem;
        }
      }
    }

    .reviews-head {
      text-align: center;
      margin-top: 3rem;
      .btn {
        margin-left: 1rem;
        color: $color-general;
        border-color: $color-general;
      }
    }

    .box {
      max-width: 60%;
      margin: 1rem auto;
      background-color: #fafafa;
      border: none;
    }

  }

  #desc {
    .v-centered {
      @media (max-width: 768px) {
        //display: block;
        overflow: initial;
        flex-direction: column;
        //div.col-sm-6:nth-child(2n){
        //  order: 1;
        //}
      }
    }
    @media (max-width: 768px) {
      .v-centered:nth-child(2n - 1) >.col-sm-6:nth-child(2n) {
        order: 1;
      }
      .v-centered:nth-child(2n - 1) >.col-sm-6:nth-child(2n - 1) {
        order: 2;
      }
    }
    .img-fluid {
      width: 100%;
      padding-bottom: 0.5rem;
    }
    h3 {
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 220%;
      margin: 0;
      padding: 0 3rem 0 4rem;
      text-align: left;
      @media (max-width: 576px) {
        text-align: center;
      }
    }
    p {
      font-family: Arial, sans-serif;      
      font-size: 1.3rem;      
      padding: 1.5rem 3.2rem .8rem 3.2rem;
      @media (max-width: 576px) {
        padding: 0;
      }
    }
    .col-sm-6 {
      padding: 0;
    }
    .text {
      padding: 2rem;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .nav-tabs .nav-item {
    margin-left: 0;
  }

}

@media (max-width: 760px) {
  #product-detail > .row {
    padding: 0 0;
  }
  .detailed-desc-reviews .nav-tabs {
    border: none;
    .nav-link {
      border: none;
    }
  }

}